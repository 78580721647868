import React from 'react'
import { Section } from '../Section'
import styled from 'styled-components'
import BgImg from '../../images/getstarted-bg.jpg'
import PhoneCallIcon from '../../svg/phone-call.svg'
import BreakpointDown from '../Media/BreakpointDown'

const GetStartedBg = styled.div`
  background:url(${BgImg});
  background-size: cover;
  background-position: center center;
  border: 10px solid rgba(221,221,221,0.55);
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 50px;
  @media(max-width:1280px){
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media(max-width:1024px){
    padding:25px;
  }
  ${BreakpointDown.md`
    padding: 25px 15px;
  `}
 `
const GetStartedWrapper = styled.div`
  display:flex; 
  justify-content: space-between;
  align-items:center;
  flex-wrap:wrap;
 `

const LeftWrapper = styled.div`
     flex: 0 0 60%;
     max-width: 60%; 
     @media(max-width:1024px){
      flex: 0 0 100%;
      max-width: 100%;
      }
`
const RightWrapper = styled.div`
     flex: 0 0 auto;
     ${BreakpointDown.lg`
      margin-left:auto;
      margin-right:auto;
     `}
     ${BreakpointDown.md`
       flex:0 0 100%;
     `}
`
const LinkBtn = styled.a`
  background:#529A00;
  padding:18px 32px;
  display:flex;
  color: #FFFFFF;
  font-size:24px;
  font-weight:900;
  margin-right: -10px;
  align-items:center;
  @media(max-width:1024px){
    padding:18px 15px;
  }
  ${BreakpointDown.md`
   padding: 10px 15px;
   width:100%;
   margin-right:auto;
   text-align:center;
  `}
  ${BreakpointDown.sm`
   display:flex;
   justify-content:center;
  `}
`
const HeadingTitle = styled.div`
  line-height:46px;
  margin-bottom:8px;
  ${BreakpointDown.md`
      line-height:36px;
      margin-bottom:8px;
  `}
    ${BreakpointDown.sm`
      line-height:26px;
  `}
  span{
    color:#529A00;
  }
`


const GetStarted = () => (
  <Section pb="100px" pt="100px" mpt="40px" mpb="40px" xpt="60px" xpb=" 60px" xxpt="50px" xxpb="150px" bgColor="#F8F8F8">
    <div className="container">
        <GetStartedBg>
          <GetStartedWrapper>
            <LeftWrapper>
              <HeadingTitle className="h1">Tell us about your  <span>particular goals & needs; </span> WYN can deliver the solutions your business needs!</HeadingTitle>
              <p>Shoot us a message or give us a call; we’re here to help!</p>
            </LeftWrapper>
            <RightWrapper>
              <LinkBtn href="tel:3368990555"><img src={PhoneCallIcon} alt="Get Started" />(336) 899-0555</LinkBtn>
            </RightWrapper>
          </GetStartedWrapper>
        </GetStartedBg>
    </div>
  </Section>

)
export default GetStarted;